import React from 'react'
import { PageRendererProps } from 'gatsby'
import ScheduleComponent from '../../components/live/schedule'
import Layout from '../../templates/LiveLayout'
import { withErrorHandler } from '../../components/errorHandler'

const AthleteSchedule = (props: PageRendererProps): React.ReactElement => (
  <Layout title="Workout Schedule" location={props.location}>
    <ScheduleComponent location={props.location} />
  </Layout>
)

export default withErrorHandler(AthleteSchedule)
